<template>
  <div>
    <div class="inner job-posting pb0">
      <div class="tit req-type">
        <h1>기본 정보 수정</h1>
        <p class="stit">계정의 기본 정보들을 수정할 수 있습니다.</p>
      </div>
      <div class="sub-cont">
        <div class="myp-edit-wrap">
          <dl>
            <dt>계정정보</dt>
            <dd>
              <div class="form-item-wrap">
                <div class="label">아이디</div>
                <input type="hidden" class="inp" v-model="userId" readonly />
                <input
                  type="text"
                  class="inp userId"
                  v-model="hiddenId"
                  readonly
                />
              </div>
              <div class="form-item-wrap">
                <div class="label">이메일</div>
                <input type="text" class="inp" v-model="email" ref="email" />
              </div>
            </dd>
          </dl>
          <dl>
            <dt>기본정보</dt>
            <dd>
              <div class="form-item-wrap">
                <div class="label">회원 사진</div>

                <!-- ㅅㅏ진업로드 UI 추가 -->
                <input
                  type="text"
                  class="inp addr1 file-value"
                  :value="photoFileName"
                  readonly
                />
                <input
                  type="file"
                  ref="fileInput"
                  accept="image/*"
                  class="hidden"
                  @change="onFileChanged"
                />
                <button
                  type="button"
                  id="btn-file-upload"
                  class="btn-func"
                  @click="onUploadClick"
                >
                  사진업로드
                </button>

                <div class="profile-item-wrap">
                  <!-- 사진 제거 UI 추가 -->
                  <div class="pic" :style="userImg">
                    <!-- <button
                      type="button"
                      class="btn-file-delete"
                      @click="deletePhoto"
                    >
                      <i class="el-icon-close"></i>
                    </button> -->
                  </div>

                  <label>
                    <input
                      type="checkbox"
                      v-model="photoUseYn"
                      true-value="Y"
                      false-value="N"
                    />
                    <span>사진공개</span>
                  </label>
                </div>
                <!--<button-- type="button" class="btn-edit">
                  <img src="~assets/image/ico/ico-edit.png" />
                  <span>수정</span>
                </button-->
              </div>
              <div class="form-item-wrap" @click="findAddress">
                <div class="label">지역</div>
                <input
                  type="text"
                  class="inp addr1"
                  ref="address"
                  v-model="address"
                  readonly
                />
                <button type="button" class="btn-func">주소찾기</button>
              </div>
              <div class="form-item-wrap mt10">
                <div class="label">나머지 주소</div>
                <input
                  type="text"
                  ref="addressDetail"
                  class="inp addr2"
                  v-model="addressDetail"
                />
              </div>
              <div v-if="isEmployee" class="form-item-wrap">
                <div class="label">생년월일</div>
                <!-- ㅅㅏ진업로드 UI 추가 -->
                <input
                  v-model="birthday"
                  name="birthday"
                  ref="birthday"
                  type="text"
                  class="inp"
                  readonly
                />
              </div>
              <div v-if="isEmployee" class="form-item-wrap">
                <div class="label">성별</div>
                <select class="select" v-model="gender">
                  <option value="NONE">선택</option>
                  <option
                    v-for="(data, idx) in genders"
                    :key="`if_${idx}`"
                    :value="data.code"
                    :selected="data.code == gender"
                  >
                    {{ `${data.codeValue} ` }}
                  </option>
                </select>
              </div>
            </dd>
          </dl>

          <template v-if="isEmployee">
            <dl>
              <dt>연락처 정보</dt>
              <dd>
                <div class="form-item-wrap">
                  <div class="label">회원 이름</div>
                  <input
                    type="text"
                    class="inp"
                    name="userNm"
                    v-model="userNm"
                    ref="userNm"
                    readonly
                  />
                  <!--button type="button" class="btn-edit">
                  <img src="~assets/image/ico/ico-edit.png" />
                  <span>수정</span>
                </!--button-->
                </div>
                <div class="form-item-wrap">
                  <div class="label">핸드폰 번호</div>
                  <input
                    type="number"
                    class="inp"
                    v-model="phone"
                    ref="phone"
                    readonly
                  />
                </div>
              </dd>
            </dl>
            <dl>
              <dt>외국인 정보</dt>
              <dd>
                <div class="form-item-wrap">
                  <div class="label">국가</div>
                  <select class="select" v-model="nationalityCode">
                    <option value="NONE">선택</option>
                    <option
                      v-for="(nation, idx) in nationalities"
                      :key="`if_${idx}`"
                      :value="nation.code"
                      :selected="nation.code == nationalityCode"
                    >
                      {{ `${nation.codeValue} ` }}
                    </option>
                  </select>
                </div>
                <div class="form-item-wrap">
                  <div class="label">여권번호</div>
                  <input
                    type="text"
                    class="inp"
                    v-model="passport"
                    ref="passport"
                  />
                </div>
                <div class="form-item-wrap">
                  <div class="label">외국인등록번호</div>
                  <input
                    type="text"
                    class="inp"
                    v-model="foreignerRegNo"
                    ref="foreignerRegNo"
                  />
                </div>
                <div class="form-item-wrap">
                  <div class="label">비자 종류</div>
                  <select class="select" v-model="visaType">
                    <option value="NONE">선택</option>
                    <option
                      v-for="(visaCode, idx) in visaCodeList"
                      :key="`if_${idx}`"
                      :value="visaCode.code"
                      :selected="visaCode.code == visaType"
                    >
                      {{ `${visaCode.codeValue} ` }}
                    </option>
                  </select>
                </div>
              </dd>
            </dl>
          </template>
          <template v-else>
            <dl>
              <dt>기업 정보</dt>
              <dd>
                <div class="form-item-wrap">
                  <div class="label">숙박업 유형</div>
                  <select
                    v-model="bizTypeCode"
                    class="select"
                    ref="bizTypeCode"
                  >
                    <option value="NONE">선택</option>
                    <option
                      v-for="(typeOption, idx) in businessTypeList"
                      :key="`to_${idx}`"
                      :value="typeOption.code"
                      :selected="typeOption.code == bizTypeCode"
                    >
                      {{ typeOption.codeValue }}
                    </option>
                  </select>
                </div>
                <div class="form-item-wrap">
                  <div class="label">기업명</div>
                  <input type="text" class="inp" v-model="companyNm" />
                </div>
                <div class="form-item-wrap">
                  <div class="label">사업자 번호</div>
                  <input type="text" class="inp" v-model="bizNo" readonly />
                </div>
                <div class="form-item-wrap">
                  <div class="label">세금계산서용 이메일</div>
                  <input
                    type="text"
                    class="inp"
                    v-model="taxbillEmail"
                    ref="taxbillEmail"
                  />
                </div>
              </dd>
            </dl>

            <dl>
              <dt>담당자 정보</dt>
              <dd>
                <div class="form-item-wrap">
                  <div class="label">담당자 이름</div>
                  <input type="text" class="inp" v-model="managerName" />
                </div>
                <div class="form-item-wrap">
                  <div class="label">직책</div>
                  <input type="text" class="inp" v-model="managerPosition" />
                </div>
                <div class="form-item-wrap">
                  <div class="label">이메일</div>
                  <input
                    type="text"
                    class="inp"
                    v-model="managerEmail"
                    ref="managerEmail"
                  />
                </div>
                <div class="form-item-wrap">
                  <div class="label">핸드폰 번호</div>
                  <input
                    type="number"
                    class="inp"
                    v-model="managerPhone"
                    ref="managerPhone"
                  />
                </div>
                <div class="form-item-wrap">
                  <div class="label">전화 번호</div>
                  <input
                    type="number"
                    class="inp"
                    v-model="managerTel"
                    ref="managerTel"
                  />
                </div>
                <div class="form-item-wrap">
                  <div class="label">팩스 번호</div>
                  <input
                    type="number"
                    class="inp"
                    v-model="managerFax"
                    ref="managerFax"
                  />
                </div>
              </dd>
            </dl>

            <dl>
              <dt>회계 담당자 정보</dt>
              <dd>
                <div class="form-item-wrap">
                  <div class="label">담당자 이름</div>
                  <input type="text" class="inp" v-model="actManagerName" />
                </div>
                <div class="form-item-wrap">
                  <div class="label">직책</div>
                  <input type="text" class="inp" v-model="actManagerPosition" />
                </div>
                <div class="form-item-wrap">
                  <div class="label">이메일</div>
                  <input
                    type="text"
                    class="inp"
                    v-model="actManagerEmail"
                    ref="actManagerEmail"
                  />
                </div>
                <div class="form-item-wrap">
                  <div class="label">핸드폰 번호</div>
                  <input
                    type="number"
                    class="inp"
                    v-model="actManagerPhone"
                    ref="actManagerPhone"
                  />
                </div>
                <div class="form-item-wrap">
                  <div class="label">전화 번호</div>
                  <input
                    type="number"
                    class="inp"
                    v-model="actManagerTel"
                    ref="actManagerTel"
                  />
                </div>
                <div class="form-item-wrap">
                  <div class="label">팩스 번호</div>
                  <input
                    type="number"
                    class="inp"
                    v-model="actManagerFax"
                    ref="actManagerFax"
                  />
                </div>
              </dd>
            </dl>
          </template>
        </div>
      </div>
    </div>
    <div class="myp-btn-wrap">
      <button type="button" class="btn-save01" @click="save">저장</button>
    </div>
  </div>
</template>
<script>
import {
  getBasicUserInfo,
  getCompanyInfo,
  getBusinesTypeList,
  getCountryCodeList,
  putBasicUserInfo,
  putCompanyInfo,
  getVisaCodeList,
  uploadUserImg,
} from 'Api/modules'
import { mapGetters } from 'vuex'
import { searchAddressGetResult } from 'Utils/message'
import { getCodeValue } from 'Utils/codes'
import { USER_TYPE } from 'Configs/user'

export default {
  data() {
    return {
      userId: '',
      hiddenId: '',
      email: '',
      address: '',
      addressDetail: '',
      region1DepthName: '',
      region2DepthName: '',
      region3DepthName: '',
      userNm: '', //기업명
      phone: '',
      nationalityCode: '',
      foreignerRegNo: '',
      passport: '',
      visaType: '',
      gender: '',

      photoUseYn: '',
      photo: '', //사진 파일
      photoFileName: '',
      //photoId: '', // 사진파일 아이디

      //기업
      bizTypeCode: '',
      bizTypeName: '',
      bizNo: '',
      taxbillEmail: '',
      managerName: '',
      managerPosition: '',
      managerEmail: '',
      managerPhone: '',
      managerTel: '',
      managerFax: '',
      actManagerName: '',
      actManagerPosition: '',
      actManagerEmail: '',
      actManagerPhone: '',
      actManagerTel: '',
      actManagerFax: '',

      nationalities: [],
      visaCodeList: [],
      genders: [
        { code: '0', codeValue: '남' },
        { code: '1', codeValue: '여' },
      ],

      businessTypeList: [],

      inputPhoto: null,

      isDelete: false,

      companyNm: '',
      birthday: '',
    }
  },
  computed: {
    ...mapGetters(['accountUserImg']),
    userImg() {
      let photo = ''
      if (this.isDelete) {
        photo =
          this.account.userType === USER_TYPE.EMPLOYEE
            ? require('assets/image/etc/etc-user-noimg.svg')
            : require('assets/image/etc/etc-hotel-noimg.svg')
      } else {
        photo = this.inputPhoto || this.accountUserImg
      }

      return `background: url(${photo}) no-repeat center center;`
    },
  },
  methods: {
    InformationStatus() {
      if (
        this.account.joinType === 'KAKAO' ||
        this.account.joinType === 'NAVER'
      ) {
        window.$('input[name=userNm]').attr('readonly', false)
      } else {
        window.$('input[name=userNm]').attr('readonly', true)
      }
      if (this.birthday === '') {
        console.log(this.birthday)
        window.$('input[name=birthday]').attr('readonly', false)
        window.$('input[name=birthday]').attr('placeholder', 'ex)20191201')
      } else {
        console.log('b')
        window.$('input[name=birthday]').attr('readonly', true)
        window.$('input[name=birthday]').removeAttr('placeholder')
      }
    },
    findAddress() {
      if (this.isApp) {
        searchAddressGetResult(({ sido, sigungu, bname, address }) => {
          this.region1DepthName = sido
          this.region2DepthName = sigungu
          this.region3DepthName = bname
          this.address = address
          this.addressDetail = ''
        })
      } else {
        //카카오 지도 발생
        new window.daum.Postcode({
          oncomplete: data => {
            //선택시 입력값 세팅
            const { sido, sigungu, bname, address } = data
            this.region1DepthName = sido
            this.region2DepthName = sigungu
            this.region3DepthName = bname
            this.address = address
            this.addressDetail = ''
            console.error(sido, sigungu, bname, address)
          },
        }).open()
      }
    },
    onUploadClick() {
      this.$refs['fileInput'].click()
    },
    async onFileChanged(event) {
      // 1M
      var maxFileSize = 1 * 1024 * 1000
      // console.log(file.size)
      const file = event.target.files[0]
      if (file) {
        // 파일 크기 제한

        //허용 파일 타입
        const isAcceptable = [
          'image/jpeg',
          'image/png',
          'image/bmp',
          'image/gif',
        ].includes(file.type)
        if (file.size > maxFileSize) {
          this.$toasted.error('사진 크기는 1M이하만 가능합니다.')
          return false
        } else if (!isAcceptable) {
          this.$toasted.error('허용하지 않는 파일 형식입니다.')
          return false
        } else {
          this.$toasted.show('사진이 업로드되었습니다.')
        }
        if (isAcceptable) {
          // todo:: 파일업로드 api 태우고, 파일값 리턴받음.

          this.photoFileName = file.name

          try {
            const res = await uploadUserImg({ uploadFile: file })
            this.photo = res
            var reader = new FileReader()
            reader.onload = e => {
              this.inputPhoto = e.target.result
            }
            reader.readAsDataURL(file)
            this.isDelete = false
          } catch (e) {
            console.error(e)
            // this.deleteAttachedFile()
            return
          }
        }
        // else {
        //   this.deleteAttachedFile()
        // }
      }
    },
    deleteAttachedFile() {
      this.inputPhoto = null
      this.photo = null
      this.photoFileName = ''
      this.$refs['fileInput'].value = ''
    },
    // deletePhoto() {
    //   this.isDelete = true
    //   this.deleteAttachedFile()
    // },
    birthDate(data) {
      return /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/.test(
        data,
      )
    },
    validNumber(data) {
      return /^[0-9]*$/.test(data)
    },
    validEmail(data) {
      return /[-\d\S.+_]+@[-\d\S.+_]+\.[\S]{2,4}/.test(data)
    },
    validPhone(data) {
      return data.length <= 11 && /^[0-9]*$/.test(data)
    },
    validUserNm(data) {
      return /^[가-힣]{2,5}$/.test(data)
    },
    chkIsValidInput(saveData) {
      // 폼 데이터 정합성 검사
      if (saveData.email && !this.validEmail(saveData.email)) {
        this.$toasted.error('이메일 형식이 올바르지 않습니다.')
        this.email = ''
        this.$refs['email'].focus()
        return false
      }
      if (!saveData.address) {
        this.$toasted.error('주소를 입력해주세요.')
        this.address = ''
        this.$refs['address'].focus()
        this.findAddress()
        return false
      }
      if (!saveData.addressDetail) {
        this.$toasted.error('나머지 주소를 입력해주세요.')
        this.addressDetail = ''
        this.$refs['addressDetail'].focus()
        return false
      }
      if (!saveData.birthday) {
        this.$toasted.error('생년월일을 입력해주세요.')
        this.$refs['birthday'].focus()
        return false
      } else if (!this.birthDate(saveData.birthday)) {
        this.$toasted.error('생년월일 형식이 올바르지 않습니다.')
        this.$refs['birthday'].focus()
        return false
      }
      if (saveData.phone && !this.validPhone(saveData.phone)) {
        this.$toasted.error('핸드폰 번호 형식이 올바르지 않습니다.')
        this.phone = ''
        this.$refs['phone'].focus()
        return false
      }

      // 개인 회원
      if (this.isEmployee) {
        if (this.gender == 'NONE') {
          this.$toasted.error('성별을 선택해 주세요.')
          return false
        }
        if (!saveData.userNm) {
          this.$toasted.error('이름을 입력해주세요.')
          this.$refs['userNm'].focus()
          return false
        } else if (
          (!this.validUserNm(saveData.userNm) &&
            this.account.joinType === 'KAKAO') ||
          (!this.validUserNm(saveData.userNm) &&
            this.account.joinType === 'NAVER')
        ) {
          this.$toasted.error('이름 형식이 올바르지 않습니다.')
          this.$refs['userNm'].focus()
          return false
        }
        if (saveData.passport && !this.validNumber(saveData.passport)) {
          this.$toasted.error('여권번호 형식이 올바르지 않습니다.')
          this.passport = ''
          this.$refs['passport'].focus()
          return false
        }

        if (
          saveData.foreignerRegNo &&
          !this.validNumber(saveData.foreignerRegNo)
        ) {
          this.$toasted.error('외국인등록번호 형식이 올바르지 않습니다.')
          this.foreignerRegNo = ''
          this.$refs['foreignerRegNo'].focus()
          return false
        }
      } else {
        // 기업 회원
        if (saveData.taxbillEmail && !this.validEmail(saveData.taxbillEmail)) {
          this.$toasted.error('세금계산서용 이메일 형식이 올바르지 않습니다.')
          this.taxbillEmail = ''
          this.$refs['taxbillEmail'].focus()
          return false
        }

        if (saveData.managerEmail && !this.validEmail(saveData.managerEmail)) {
          this.$toasted.error('담당자 이메일 형식이 올바르지 않습니다.')
          this.managerEmail = ''
          this.$refs['managerEmail'].focus()
          return false
        }

        if (saveData.managerPhone && !this.validPhone(saveData.managerPhone)) {
          this.$toasted.error('담당자 핸드폰 번호 형식이 올바르지 않습니다.')
          this.managerPhone = ''
          this.$refs['managerPhone'].focus()
          return false
        }

        if (saveData.managerTel && !this.validPhone(saveData.managerTel)) {
          this.$toasted.error('담당자 전화 번호 형식이 올바르지 않습니다.')
          this.managerTel = ''
          this.$refs['managerTel'].focus()
          return false
        }

        if (saveData.managerFax && !this.validPhone(saveData.managerFax)) {
          this.$toasted.error('담당자 팩스 번호 형식이 올바르지 않습니다.')
          this.managerFax = ''
          this.$refs['managerFax'].focus()
          return false
        }

        if (
          saveData.actManagerEmail &&
          !this.validEmail(saveData.actManagerEmail)
        ) {
          this.$toasted.error('회계 담당자 이메일 형식이 올바르지 않습니다.')
          this.actManagerEmail = ''
          this.$refs['actManagerEmail'].focus()
          return false
        }

        if (
          saveData.actManagerPhone &&
          !this.validPhone(saveData.actManagerPhone)
        ) {
          this.$toasted.error(
            '회계 담당자 핸드폰 번호 형식이 올바르지 않습니다.',
          )
          this.actManagerPhone = ''
          this.$refs['actManagerPhone'].focus()
          return false
        }

        if (
          saveData.actManagerTel &&
          !this.validPhone(saveData.actManagerTel)
        ) {
          this.$toasted.error('회계 담당자 전화 번호 형식이 올바르지 않습니다.')
          this.actManagerTel = ''
          this.$refs['actManagerTel'].focus()
          return false
        }

        if (
          saveData.actManagerFax &&
          !this.validPhone(saveData.actManagerFax)
        ) {
          this.$toasted.error('회계 담당자 팩스 번호 형식이 올바르지 않습니다.')
          this.actManagerFax = ''
          this.$refs['actManagerFax'].focus()
          return false
        }
      }
      return true
    },
    async findNationalities() {
      this.nationalities = await getCountryCodeList()
    },
    async findVisaCodeList() {
      this.visaCodeList = await getVisaCodeList()
    },
    async save() {
      // 개인 정보 와  기업정보를 저장하는 옵션 다름.
      let saveData = {}
      if (this.isEmployee) {
        saveData = {
          userId: this.userId,
          email: this.email ? this.email : '',
          photo: this.photo ? this.photo : '',
          photoUseYn:
            // this.photoUseYn === true || this.photoUseYn === 'Y' ? 'Y' : 'N',
            this.photoUseYn === 'Y' ? 'Y' : 'N',
          address: this.address ? this.address : '',
          addressDetail: this.addressDetail ? this.addressDetail : '',
          region1DepthName: this.region1DepthName ? this.region1DepthName : '',
          region2DepthName: this.region2DepthName ? this.region2DepthName : '',
          region3DepthName: this.region3DepthName ? this.region3DepthName : '',
          userNm: this.userNm ? this.userNm : '',
          companyNm: this.companyNm ? this.companyNm : '',
          phone: this.phone ? this.phone : '',
          nationality:
            this.nationalityCode != 'NONE' ? this.nationalityCode : '',
          passport: this.passport ? this.passport : '',
          foreignerRegNo: this.foreignerRegNo ? this.foreignerRegNo : '',
          visaType: this.visaType != 'NONE' ? this.visaType : '',
          birthday: this.birthday ? this.birthday : '',
          gender: this.gender,
        }
        if (!this.chkIsValidInput(saveData)) return false

        //입력 오류
        // if (!this.validateInput(saveData)) return false
        // if (saveData) return false
        try {
          await putBasicUserInfo(saveData)
          this.isSaved = true
          this.$toasted.show('저장되었습니다.')
          return true
        } catch (e) {
          console.error(e)
          return false
        }
      } else {
        // 기업 정보
        saveData = {
          userId: this.userId,
          email: this.email ? this.email : '',
          photo: this.photo ? this.photo : '',
          photoUseYn:
            // this.photoUseYn === true || this.photoUseYn === 'Y' ? 'Y' : 'N',
            this.photoUseYn === 'Y' ? 'Y' : 'N',
          address: this.address ? this.address : '',
          addressDetail: this.addressDetail ? this.addressDetail : '',
          region1DepthName: this.region1DepthName ? this.region1DepthName : '',
          region2DepthName: this.region2DepthName ? this.region2DepthName : '',
          region3DepthName: this.region3DepthName ? this.region3DepthName : '',
          userNm: this.userNm ? this.userNm : '',
          birthday: this.birthday ? this.birthday : '',
          companyNm: this.companyNm ? this.companyNm : '',
          phone: this.phone ? this.phone : '',
          // 기업정보가 필요함.
          bizTypeCode: this.bizTypeCode != 'NONE' ? this.bizTypeCode : '',
          bizTypeName: getCodeValue(this.businessTypeList, this.bizTypeCode),
          bizNo: this.bizNo ? this.bizNo.split('-').join('') : '',
          taxbillEmail: this.taxbillEmail ? this.taxbillEmail : '',
          managerName: this.managerName ? this.managerName : '',
          managerPosition: this.managerPosition ? this.managerPosition : '',
          managerEmail: this.managerEmail ? this.managerEmail : '',
          managerPhone: this.managerPhone ? this.managerPhone : '',
          managerTel: this.managerTel ? this.managerTel : '',
          managerFax: this.managerFax ? this.managerFax : '',
          actManagerName: this.actManagerName ? this.actManagerName : '',
          actManagerPosition: this.actManagerPosition
            ? this.actManagerPosition
            : '',
          actManagerEmail: this.actManagerEmail ? this.actManagerEmail : '',
          actManagerPhone: this.actManagerPhone ? this.actManagerPhone : '',
          actManagerTel: this.actManagerTel ? this.actManagerTel : '',
          actManagerFax: this.actManagerFax ? this.actManagerFax : '',
        }
        if (!this.chkIsValidInput(saveData)) return false
        try {
          await putCompanyInfo(saveData)
          this.isSaved = true
          this.$toasted.show('저장되었습니다.')
          setTimeout(() => {
            this.$router.go()
          }, 300)
          return true
        } catch (e) {
          console.error(e)
          return false
        }
      }
    },

    async fetchPersonalInfo() {
      const res = await getBasicUserInfo()
      this.userId = res.userId
      this.email = res.email
      this.address = res.address
      this.addressDetail = res.addressDetail
      this.region1DepthName = res.region1DepthName
      this.region2DepthName = res.region2DepthName
      this.region3DepthName = res.region3DepthName
      // this.photoUseYn = res.photoUseYn === 'Y' ? true : false
      this.photoUseYn = res.photoUseYn
      this.userNm = res.userNm
      //this.photoId = res.photoId
      this.photo = res.photo
      this.photoFileName = res.photoFileName

      this.phone = res.phone
      this.nationalityCode = res.nationality ? res.nationality : 'NONE'
      this.foreignerRegNo = res.foreignerRegNo || ''
      this.passport = res.passport || ''
      this.visaType = res.visaType ? res.visaType : 'NONE'
      this.birthday = res.birthday ? res.birthday : ''
      this.gender = res.gender ? res.gender : 'NONE'
    },
    async fetchCompanyInfo() {
      const res = await getCompanyInfo({ userId: this.account.userId })
      this.userId = res.userId
      this.email = res.email
      this.address = res.address
      this.addressDetail = res.addressDetail
      this.region1DepthName = res.region1DepthName
      this.region2DepthName = res.region2DepthName
      this.region3DepthName = res.region3DepthName
      this.photoUseYn = res.photoUseYn === 'Y' ? true : false
      this.userNm = res.userNm
      this.companyNm = res.companyNm
      this.birthday = res.birthday || ''
      //this.photoId = res.photoId
      this.photo = res.photo
      this.photoFileName = res.photoFileName

      this.bizTypeCode = res.bizTypeCode ? res.bizTypeCode : 'NONE'
      this.bizTypeName = res.bizTypeName
      this.bizNo =
        res.bizNo.slice(0, 3) +
        '-' +
        res.bizNo.slice(3, 5) +
        '-' +
        res.bizNo.slice(5, 10)

      this.taxbillEmail = res.taxbillEmail
      this.managerName = res.managerName
      this.managerPosition = res.managerPosition
      this.managerEmail = res.managerEmail
      this.managerPhone = res.managerPhone
      this.managerTel = res.managerTel
      this.managerFax = res.managerFax
      this.actManagerName = res.actManagerName
      this.actManagerPosition = res.actManagerPosition
      this.actManagerEmail = res.actManagerEmail
      this.actManagerPhone = res.actManagerPhone
      this.actManagerTel = res.actManagerTel
      this.actManagerFax = res.actManagerFax
    },
  },
  async created() {
    // 개인 유저 정보 조회
    if (this.isEmployee) {
      // 국가 목록 조회
      await this.findNationalities()
      // visa 목록 조회
      await this.findVisaCodeList()
      // 개인 유저 정보 조회
      await this.fetchPersonalInfo()
      // 카카오/네이버 = 이름 변경 가능 && 생년월일 빈 값 입력 가능
      await this.InformationStatus()
    } else {
      this.businessTypeList = await getBusinesTypeList()
      await this.fetchCompanyInfo()
    }
    // this.hiddenId = this.account.joinType === 'KAKAO' ? '카카오' : this.userId
    // this.hiddenId = this.account.joinType === 'NAVER' ? '네이버' : this.userId
    // this.hiddenId = this.account.joinType === 'HOGOO' ? '호구' : this.userId
    if (this.account.joinType === 'KAKAO') {
      this.hiddenId = '카카오'
    }
    if (this.account.joinType === 'NAVER') {
      this.hiddenId = '네이버'
    } else if (this.account.joinType === 'HOGOO') {
      this.hiddenId = this.userId
    }
  },
}
</script>

<style></style>
