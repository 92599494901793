import Vue from 'vue'

export const listToString = (list, key = 'value') => {
  if (list) {
    const result = list
      .map(option => {
        if (option.on) {
          return option[key]
        }
        return ''
      })
      .filter(option => option !== null && option !== '')
    return result.join(',') //,뒤 공백 여부 확인 필요
  }
  return ''
}

export const parseCodes = (list, string, key = 'value') => {
  if (string) {
    const codeList = string.split(',') //,뒤 공백 여부 확인 필요
    codeList.forEach(code => {
      list.find(optionItem => {
        if (optionItem[key] === code) Vue.$set(optionItem, 'on', true)
      })
    })
  }
}

export const getCodeValue = (list, key) => {
  let result = ''
  if (key) {
    list.forEach(entry => {
      if (entry.code === key) {
        result = entry.codeValue
      }
    })
  }
  return result
}
